import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Filter from './components/Filter/Filter';
import ItemGrid from './components/ItemGrid/ItemGrid';
import './App.css';
import Footer from './components/Footer/Footer';
import AdminDashboard from './components/Admin/AdminDashboard/AdminDashboard';
import AddEditItem from './components/Admin/AddEditItem/AddEditItem';
// const App = () => {
  

//   return (
//     <div className="app">
//       <Header onSearch={handleSearch} /> {/* Pass the handleSearch function as a prop */}
//       <div className="banner-container">
//         <Banner />
//       </div>
//       <div className="content">
//         {/* <Filter /> */}
//         <ItemGrid searchQuery={searchQuery} /> {/* Pass the searchQuery as a prop */}
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default App;



function App() {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase()); // Save the search query
  };
  return (
    <Router>
      <Header onSearch={handleSearch}/>
      <Routes>
        <Route path="/" element={
          <>
            <Banner />
            {/* <Filter /> */}
            <ItemGrid searchQuery={searchQuery}/>
            <Footer />
          </>
        } />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/admin/add" element={<AddEditItem />} />
        <Route path="/admin/edit/:id" element={<AddEditItem />} />
      </Routes>
    </Router>
  );
}

export default App;


