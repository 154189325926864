import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Filter from '../Filter/Filter';
import './ItemGrid.css';
import { fetchItems } from '../Admin/CloudService'; // Ensure this path is correct

const ItemGrid = ({ searchQuery }) => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState('All');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getItems = async () => {
      try {
        const data = await fetchItems();
        setItems(data);
      } catch (error) {
        setError('Failed to load items.');
      } finally {
        setLoading(false);
      }
    };

    getItems();
  }, []);

  // Ensure that searchQuery is defined and is a string
  const safeSearchQuery = (searchQuery || '').toLowerCase();

  const filteredItems = items
    .filter(item => (filter === 'All' || item.category === filter))
    .filter(item => item.name?.toLowerCase().includes(safeSearchQuery));

  useEffect(() => {
    if (searchQuery) {
      setFilter('All');
    }
  }, [searchQuery]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <div style={{ marginTop: 12 }}>
        <Filter onFilterChange={(selectedFilter) => setFilter(selectedFilter)} />
      </div>
      <div className="item-grid">
        {filteredItems.map(item => (
          <div key={item.id} className="item" onClick={() => setSelectedItem(item)}>
            <img src={item.image} alt={item.name} />
            <h3>{item.name}</h3>
            <h5 style={{ color: "#919090" }}>{item.description}</h5>
            <div>
              <p>{item.price}</p>
              <a href={`https://wa.me/+962785480490?text=I%20want%20to%20order%20${encodeURIComponent(item.name)}%20for%20${encodeURIComponent(item.description)}%20for%20${encodeURIComponent(item.image)}%20for%20${encodeURIComponent(item.price)}`} target="_blank" rel="noopener noreferrer">
                <button>Order Now</button>
              </a>
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={!!selectedItem}
        onRequestClose={() => setSelectedItem(null)}
        contentLabel="Item Details"
        className="modal"
        overlayClassName="overlay"
      >
        <button className="close-button" onClick={() => setSelectedItem(null)}>X</button>
        <h2>{selectedItem?.name}</h2>
        <img src={selectedItem?.image} alt={selectedItem?.name} />
        <p>{selectedItem?.description}</p>
        <p><strong>{selectedItem?.price}</strong></p>
        <a href={`https://wa.me/+962785480490?text=I%20want%20to%20order%20${encodeURIComponent(selectedItem?.name)}%20for%20${encodeURIComponent(selectedItem?.description)}%20for%20${encodeURIComponent(selectedItem?.image)}%20for%20${encodeURIComponent(selectedItem?.price)}`} target="_blank" rel="noopener noreferrer">
          <button>Order Now</button>
        </a>
      </Modal>
    </div>
  );
};

export default ItemGrid;
