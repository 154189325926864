// Footer.js

import React from 'react';
import './Footer.css';
import developerLogo from '../../Assist/home_page_logo.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="social-media">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <div className="developer-website">
          <a href="https://semicoloncode.com" target="_blank" rel="noopener noreferrer">
            <img src={developerLogo} alt="Developer Logo" className="developer-logo" />
          </a>
        </div>
        <div className="copyright">
          <p>&copy; 2024 seen. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
