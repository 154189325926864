// src/components/Filter/Filter.js
import React from 'react';
import './Filter.css'; // Ensure you have corresponding CSS for styling
import ChildIcon from '../../Assist/child.png';
import WomenIcon from '../../Assist/women.png';
import MenIcon from '../../Assist/men.png';

const Filter = ({ onFilterChange }) => {
  return (
    <div className="filter">
      <div className="filter-item" onClick={() => onFilterChange('Men')}>
        <img src={MenIcon} alt="Men" />
        <p>Men</p>
      </div>
      <div className="filter-item" onClick={() => onFilterChange('Women')}>
        <img src={WomenIcon} alt="Women" />
        <p>Women</p>
      </div>
      <div className="filter-item" onClick={() => onFilterChange('Child')}>
        <img src={ChildIcon} alt="Child" />
        <p>Child</p>
      </div>
    </div>
  );
};

export default Filter;
