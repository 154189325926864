// src/components/Header.js
import React, { useState } from 'react';
import './Header.css'; // Ensure you have corresponding CSS for styling

const Header = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value); // Pass search term to parent
  };

  return (
    <header className="header">
      <div className="logo">Logo</div>
      <div className="search-bar">
        <input 
          type="text" 
          placeholder="Search..." 
          value={searchTerm}
          onChange={handleSearchChange} 
        />
        
      </div>
    </header>
  );
};

export default Header;
