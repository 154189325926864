// Banner.js

import React from 'react';
import Slider from 'react-slick';
import './Banner.css';
import Banner1 from '../../Assist/banner1.jpg';
import Banner2 from '../../Assist/banner2.jpg';
import Banner3 from '../../Assist/banner3.jpg';

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false, // Set to false to hide navigation arrows
  };

  return (
    <div className="banner">
      <Slider {...settings}>
        <div>
          <img src={Banner1} alt="Slide 1" />
          <div className="banner-overlay">Welcome to Slide 1</div>
        </div>
        <div>
          <img src={Banner2} alt="Slide 2" />
          <div className="banner-overlay">Explore Our Products</div>
        </div>
        <div>
          <img src={Banner3} alt="Slide 3" />
          <div className="banner-overlay">Check Out New Arrivals</div>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
