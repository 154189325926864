import { db } from '../../firebase/firebaseConfig'; // Ensure Firebase is properly initialized
import { collection, addDoc, updateDoc, doc, getDoc, deleteDoc, getDocs } from 'firebase/firestore';

const itemCollectionRef = collection(db, 'items');

export const fetchItems = async () => {
    try {
      const snapshot = await getDocs(itemCollectionRef);
      return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error('Error fetching items from Firestore:', error);
      return [];
    }
  };

export const fetchItemById = async (id) => {
  const docRef = doc(db, 'items', id);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data() : null;
};

export const addItem = async (item) => {
  await addDoc(itemCollectionRef, item);
};

export const updateItem = async (id, item) => {
  const docRef = doc(db, 'items', id);
  await updateDoc(docRef, item);
};

export const deleteItem = async (id) => {
  const docRef = doc(db, 'items', id);
  await deleteDoc(docRef);
};

