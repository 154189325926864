import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchItemById, addItem, updateItem } from '../CloudService'; // Ensure this import path is correct
import './AddEditItem.css'; // Import the CSS file for styling

const categories = ['Men', 'Women', 'Child', 'Other']; // Define your categories here

const AddEditItem = () => {
  const [item, setItem] = useState(null);
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const getItem = async () => {
        const itemData = await fetchItemById(id);
        setItem(itemData);
        setName(itemData.name);
        setImage(itemData.image);
        setDescription(itemData.description);
        setPrice(itemData.price);
        setCategory(itemData.category);
      };

      getItem();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      await updateItem(id, { name, image, description, price, category });
    } else {
      await addItem({ name, image, description, price, category });
    }
    navigate('/admin');
  };

  return (
    <div className="add-edit-item-container">
      <h2>{id ? 'Edit Item' : 'Add Item'}</h2>
      <form onSubmit={handleSubmit} className="add-edit-item-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Image URL</label>
          <input
            id="image"
            type="text"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            placeholder="Image URL"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">Price</label>
          <input
            id="price"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Price"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="" disabled>Select Category</option>
            {categories.map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="submit-button">
          {id ? 'Update' : 'Add'} Item
        </button>
      </form>
    </div>
  );
};

export default AddEditItem;
