import React from 'react';
import { Link } from 'react-router-dom';
import { fetchItems, deleteItem } from '../CloudService';
import './AdminDashboard.css'; // Import the CSS file for styling

const AdminDashboard = () => {
  const [items, setItems] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');

  React.useEffect(() => {
    const getItems = async () => {
      const data = await fetchItems();
      setItems(data);
    };
    getItems();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');
    if (confirmDelete) {
      await deleteItem(id);
      setItems(items.filter(item => item.id !== id));
    }
  };

  const filteredItems = items.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="admin-dashboard-container">
      <h1>Admin Dashboard</h1>
      <Link to="/admin/add" className="add-new-item-link">Add New Item</Link>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search items..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>
      <table className="item-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map(item => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.price}</td>
              <td>
                <Link to={`/admin/edit/${item.id}`} className="edit-link">Edit</Link>
                <button onClick={() => handleDelete(item.id)} className="delete-button">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;
